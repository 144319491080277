export const validateBankAccount = (accountNumber) => {
  if (!accountNumber.trim()) return 'Bank account number is required';
  if (/[^0-9]/.test(accountNumber)) return 'Only numbers are allowed';
  if (!/^\d{9,18}$/.test(accountNumber)) return 'Invalid account number (9-18 digits)';
  return '';
};

export const validateAmount = (amount) => {
  if (!amount) return 'Amount is required';
  if (/[^0-9.]/.test(amount)) return 'Only numbers and decimal point allowed';
  if (!/^\d+(\.\d{1,2})?$/.test(amount)) return 'Invalid amount format (e.g. 100 or 100.50)';
  if (parseFloat(amount) <= 0) return 'Amount must be positive';
  return '';
};

export const validateIfscCode = (ifscCode) => {
  if (!ifscCode.trim()) return 'IFSC code is required';
  if (!/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/.test(ifscCode)) return 'Invalid IFSC format (e.g., ABCD0123456)';
  return '';
};

export const validateAadharNumber = (aadharNumber) => {
  if (!aadharNumber.trim()) return 'Aadhar number is required';
  if (/[^0-9]/.test(aadharNumber)) return 'Only numbers are allowed';
  if (!/^\d{12}$/.test(aadharNumber)) return 'Aadhar must be 12 digits';
  return '';
};

export const validateUpiId = (upiId) => {
  if (!upiId.trim()) return 'UPI ID is required';
  if (!/^[\w.-]+@[\w]+$/.test(upiId)) return 'Invalid UPI format (e.g., name@upi)';
  return '';
};