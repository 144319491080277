import React, { useEffect, useRef, useState } from 'react';
import "./WithdrawalPage.css";
import { validateBankAccount, validateIfscCode, validateUpiId, validateAmount } from '../../../util/inputValidators';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { encryptData } from '../../../util/EncryptData';
import { FadeLoader } from 'react-spinners';
import { Clear } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { decryptData } from '../../../util/DecryptData';

const withdrawalRules = [
    "If there are 2 or more withdrawal accounts with the same ID, then all of these accounts will be blocked.",
    "If 2 or more IDs have the same IP address, then these IDs will be blocked.",
    "Bonus should be wagered @5x before you can withdraw.",
    "If 2 or more users have similar betting patterns, their accounts will be blocked.",
    "Do not make a withdrawal request without making a deposit first. Such request will be flagged as a suspicious activity."
];

const defaultFormData = {
    upiId: "",
    scannerFile: null,
    withdrawalAmount:""
};

const createErrorStructure = () => [
    { field: 'withdrawalAmount', message: '', isValid: true },
    { field: 'upiId', message: '', isValid: true },
    // { field: 'bankAccountNumber', message: '', isValid: true },
    // { field: 'ifsc', message: '', isValid: true }
];

const WithdrawalPage = () => {
    const [formData, setFormData] = useState(defaultFormData);
    const [errors, setErrors] = useState(createErrorStructure());
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedOption, setSelectedOption] = useState(''); // 'upi' or 'bank'
    const { token, userId, clientName, bankName, accountHolderName, bankAccountNumber, ifsc } = useParams();
    const [paramError, setParamError] = useState('');
    const API_ENDPOINT = "https://laxminarayan.live/api";
    const fileInputRef = useRef(null);


    // Validate parameters on component mount
    useEffect(() => {
        const missingParams = [];
        if (!token) missingParams.push('token');
        if (!userId) missingParams.push('userId');
        if (!clientName) missingParams.push('clientName');
        if (!bankName) missingParams.push('bankName');
        if (!accountHolderName) missingParams.push('accountHolderName');
        if (!bankAccountNumber) missingParams.push('bankAccountNumber');
        if (!ifsc) missingParams.push('ifsc');

        if (missingParams.length > 0) {
            const errorMsg = `Missing required parameters: ${missingParams.join(', ')}`;
            setParamError(errorMsg);
            toast.error(errorMsg);
        }
    }, [token, userId, clientName, bankName, accountHolderName, bankAccountNumber, ifsc]);




    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));

        // Clear error when user starts typing
        if (errors.some(err => err.field === name && !err.isValid)) {
            setErrors(prev => prev.map(err =>
                err.field === name ? { ...err, message: '', isValid: true } : err
            ));
        }

        // Handle UPI ID specific logic
        if (name === "upiId") {
            if (value) {
                setSelectedOption("upi");
                setFormData(prev => ({ ...prev, scannerFile: null }));
            } else if (selectedOption === "upi") {
                setSelectedOption("");
            }
        }
    };

    // Handle File Change------------------------
    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        // Get account holder name and expected filename
        const accountName = accountHolderName.toLowerCase();
        const fileExt = file.name.split('.').pop().toLowerCase();
        const expectedFilename = `${accountName}.${fileExt}`;

        // Validate file type
        const allowedTypes = [
            'image/jpeg', 'image/png', 'application/pdf',
            'image/heic', 'image/heif'
        ];
        if (!allowedTypes.includes(file.type)) {
            toast.error('Only JPG, PNG, PDF, HEIC files allowed');
            e.target.value = '';
            return;
        }

        // Validate file size (5MB max)
        if (file.size > 5 * 1024 * 1024) {
            toast.error('File exceeds 5MB limit');
            e.target.value = '';
            return;
        }

        // Convert file to Base64
        const getBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file); // Convert to Base64
                reader.onload = () => resolve(reader.result); // Result is Base64 string
                reader.onerror = (error) => reject(error);
            });
        };

        try {
            const base64String = await getBase64(file);

            // Update state with Base64 data
            setSelectedOption("scanner");
            setFormData(prev => ({
                ...prev,
                scannerFile: {
                    binary: base64String,
                    name: expectedFilename,
                    type: file.type,
                    size: file.size
                },
                upiId: ''
            }));

        } catch (error) {
            toast.error('Error processing file');
            e.target.value = '';
        }
    };

    const validateForm = () => {
        const newErrors = createErrorStructure();
        let isValid = true;

        // Validate UPI ID if selected
        if (selectedOption === 'upi') {
            newErrors[0].message = validateUpiId(formData.upiId);
        }
        newErrors[1].message = validateAmount(formData.withdrawalAmount);



        // Validate bank details if selected
        // if (selectedOption === 'scanner') {
        //     newErrors[2].message = validateBankAccount(formData.bankAccountNumber);
        //     newErrors[3].message = validateIfscCode(formData.ifsc);
        // }
        // setSelectedMethod('scanner');
        // Check if at least one option is selected
        if (!selectedOption) {
            toast.error('Please select either UPI or Scanner Upload');
            isValid = false;
        }

        // Check validation results
        newErrors.forEach((err, index) => {
            if (err.message) {
                newErrors[index].isValid = false;
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    // handle submit withdraw form
    const handleSubmit = async () => {
        if (!validateForm()) return;
        setIsSubmitting(true);
        try {
            let payload;
            if (selectedOption == "upi") {
                payload = {
                    _id: userId,
                    upiid: formData.upiId,
                    amount:formData.withdrawalAmount
                }
            } else {
                payload = {
                    File_Name: formData.scannerFile.name,
                    _id: userId,
                    Image: formData.scannerFile.binary,
                    amount:formData.withdrawalAmount
                }
            }

            // create dynamic api endpoint
            let apiEndpoint = null;
            if (selectedOption == "upi") {
                apiEndpoint = `${API_ENDPOINT}/User/upiIdWithdrawal`
            } else if (selectedOption == "scanner") {
                apiEndpoint = `${API_ENDPOINT}/User/uploadScannerWithdrawal`
            } else {
                toast.error("Invalid details please check");
                setIsSubmitting(false)
                return;
            }

            console.log("Payload ---------------", payload);
            console.log("APi Endpoint---------", apiEndpoint)

            await axios.post(
                apiEndpoint,
                { token: encryptData(payload) },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'client-name': clientName?.toUpperCase(),
                        'Authorization': `Bearer ${token}`,
                    },
                }
            )
                .then((response) => {
                    setIsSubmitting(false);
                    let API_RESPONSE = decryptData(response.data.data);
                    console.log(API_RESPONSE)
                    if (response.data.success) {
                        toast.success("Withdraw Request Send Successfully ")
                    }
                  
                })
                .catch((error) => {
                    toast.error(error.response.data.message)
                })

        } catch (error) {
            toast.error('Failed to submit withdrawal request');
        } finally {
            setFormData(defaultFormData);
            handleRemoveFile();
            setIsSubmitting(false);
        }
    };

    // remove selected file
    const handleRemoveFile = () => {
        setFormData(defaultFormData);
        setSelectedOption("");
        if (fileInputRef.current) fileInputRef.current.value = '';
    }


    if (paramError) {
        return (
            <div className="kyc-page-section_">
                <div className="kyc-page-section-container_">
                    <div className="error-message_">
                        {paramError}. Please contact support.
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="withdrawal-page-section_">
            {
                isSubmitting &&
                <div className="withdraw-page-loader-container_">
                    <FadeLoader color='#fff' className='form-loader_' />
                </div>
            }

            <div className="withdrawal-page-section-container_">
                <div className="withdrawal-instruction-box_">
                    <div className="withdrawal-instruction-box-header_">Instructions</div>
                    <div className="withdrawal-instructions-list_">
                        {withdrawalRules.map((rule, index) => (
                            <div key={index} className="withdrawal-instruction-li_">
                                {`${index + 1}. ${rule}`}
                            </div>
                        ))}
                    </div>
                </div> m

                <div className="withdrawal-page-section-header_">
                    <div className="withdrawal-details-heading_">Bank Account Details</div>
                </div>

                <div className="withdrawal-details-form_" >
                    <div className="withdrawal-details-form-group_">
                        <div className="withdrawal-details-form-group-label_">Withdrawal Amount</div>
                        <input
                            name='withdrawalAmount'
                            type="number"
                            placeholder='Withdrawal Amount'
                            className={`withdrawal-details-form-group-input_ `}
                            value={formData.withdrawalAmount}
                            onChange={handleInputChange}
                        />
                         {!errors[1].isValid && (
                                <div className="form-error_">{errors[1].message}</div>
                            )}
                    </div>

                    <div className="withdrawal-details-form-group_ upiid-and-scanner_">
                        <div className="upiid-form-group_">
                            <div className="withdrawal-details-form-group-label_ ">UPI Id (For Withdrawals)</div>
                            <input
                                name='upiId'
                                type="text"
                                placeholder='UPI Id'
                                className={`withdrawal-details-form-group-input_ ${!errors[0].isValid ? 'error' : ''} ${selectedOption == "scanner" ? 'disabled' : ''}`}
                                value={formData.upiId}
                                onChange={handleInputChange}
                                disabled={selectedOption == "scanner"}
                            />
                            {!errors[0].isValid && (
                                <div className="form-error_">{errors[0].message}</div>
                            )}
                        </div>

                        <div className="or-divider_">OR</div>
                        <div className={`scanner-input-group_ ${selectedOption == 'upi' ? 'disabled' : ''}`}>
                            {selectedOption == 'upi' && <div className="disabled-file-choose_"></div>}

                            <div className="withdrawal-details-form-group-label_  scanner-upload-label_">
                                {selectedOption == "scanner" && formData.scannerFile.name ? formData.scannerFile.name : "Upload Scanner"}</div>
                            <input
                                type="file"
                                className="scanner-input-field_"
                                onChange={handleFileChange}
                                accept="image/*,.pdf"
                                disabled={selectedOption == "upi"}
                                ref={fileInputRef}
                            />

                        </div>
                        {selectedOption == "scanner" && formData.scannerFile.name && <DeleteIcon onClick={handleRemoveFile} className='close-file-icon_' />}

                    </div>


                    <div className="withdrawal-details-form-group_">
                        <div className="withdrawal-details-form-group-label_">Account Holder Name</div>
                        <input
                            name='accountHolderName'
                            value={accountHolderName}
                            type="text"
                            placeholder='Account Holder Name'
                            className="withdrawal-details-form-group-input_"
                            readOnly
                        />
                    </div>

                    <div className="withdrawal-details-form-group_">
                        <div className="withdrawal-details-form-group-label_">Bank Name</div>
                        <input
                            name='bankName'
                            value={bankName}
                            type="text"
                            placeholder='Bank Name'
                            className="withdrawal-details-form-group-input_"
                            readOnly
                        />
                    </div>

                    <div className="withdrawal-details-form-group_">
                        <div className="withdrawal-details-form-group-label_">Bank Account Number</div>
                        <input
                            name='bankAccountNumber'
                            value={bankAccountNumber}
                            type="text"
                            placeholder='Bank Account Number'
                            className={`withdrawal-details-form-group-input_ `}
                            readOnly
                        />
                    </div>

                    <div className="withdrawal-details-form-group_">
                        <div className="withdrawal-details-form-group-label_">IFSC</div>
                        <input
                            name='ifsc'
                            value={ifsc}
                            type="text"
                            placeholder='IFSC'
                            className={`withdrawal-details-form-group-input_`}
                            readOnly
                        />
                    </div>

                    <div className="withdrawal-note_">
                        <p className="withdrawal-note-text_">
                            Note: To change your withdrawal account details, please contact customer support
                        </p>
                    </div>

                    <div className="withdrawal-details-form-actions_">
                        <button
                            type="submit"
                            className="withdrawal-details-form-action-btn_"
                            onClick={handleSubmit}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Processing...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WithdrawalPage;