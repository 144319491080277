import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import "./OSDepositIntentPage.css";
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { encryptData } from '../../util/EncryptData';
import { decryptData } from '../../util/DecryptData';
import PayingAccountsPage from './PayingAccountsPage';
import PopupBox from '../../components/PopupBox/PopupBox';
// import { API_ENDPOINT } from "../../configurations/settings";
const OSDepositIntentPage = () => {
    const { token, empId, lat, lng, userState, userCity, userEmail, userName, postCode, userId, deviceId, appVersion, platform, clientName, userMobile } = useParams();
    const [payingAccounts, setPayingAccounts] = useState([]);
    const [amount, setAmount] = useState("");
    const [loader, setLoader] = useState(false);
    const [employeeId, setEmployeeCode] = useState(empId);
    const [showPaymentMethods, setShowPaymentMethods] = useState(false);
    const [formLoader, setFormLoader] = useState(false);
    let FB_APPS = ["SB", "OM", "FAIRBETS", "SB247"];
    let API_ENDPOINT=FB_APPS.includes(clientName)?"https://fairbets.co/api":"https://laxminarayan.live/api"

    // Validate form-----------
    const validateForm = () => {
        if (!amount) {
            toast.error("Invalid Amount");
            return false;
        }
        if (!employeeId) {
            toast.error("Invalid Employee Id");
            return false;
        }
        return true; // Ensure function returns true when validation passes
    };

    // Handle Pay Now------------------
    const handlePayNow = async () => {
        if (!validateForm()) return;
        const payload = { state: userState, amount: parseInt(amount, 10) };
        console.log("payload-------", payload)
        try {
            setFormLoader(true);
            const response = await axios.post(
                `${API_ENDPOINT}/payinAccounts/getFilteredGateway`,
                { token: encryptData(payload) },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'client-name': clientName?.toUpperCase(),
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            setFormLoader(false);
            const data = decryptData(response.data.data);
            console.log("Response:", data);
            setPayingAccounts(data.payload);
            setShowPaymentMethods(true);

        } catch (error) {
            console.error("API Error:", error);
            setShowPaymentMethods(false);
            toast.error(error.response?.data?.message || "Something went wrong");
            setFormLoader(false);
        }
    };


    return (
        <>
           
            {loader && <div className={`utr-loader_ ${clientName}`}><CircularProgress className={`utr-loader-icon ${clientName}`} /></div>}
            <section className={`utr-deposit-page_ ${clientName?.toUpperCase()}`}>

                {
                    showPaymentMethods ?
                        <PayingAccountsPage
                            payingAccounts={payingAccounts}
                            clientName={clientName}
                            empCode={employeeId}
                            userCity={userCity}
                            userEmail={userEmail}
                            userId={userId}
                            userMobile={userMobile}
                            employeeId={employeeId}
                            amount={amount}
                            lat={lat}
                            lng={lng}
                            postCode={postCode}
                            platform={platform}
                            userState={userState}
                            setLoader={setLoader}
                            userName={userName}
                            token={token}
                            API_ENDPOINT={API_ENDPOINT}
                        />

                        :

                        <div className={`utr-deposit-page-container_ ${clientName}`}>
                            <div className={`utr-deposit-page-header-container_ ${clientName}`}>
                                <div className={`utr-deposit-page-heading_ ${clientName}`}>
                                    <div className={`cr-wrapper ${clientName}`}>
                                        <input name={`radio ${clientName}`} type="radio" checked />
                                        <div className={`cr-input ${clientName}`}></div>
                                        <div className={`automatic-heading_ ${clientName}`}>Automatic</div>
                                    </div>
                                </div>

                                <div className={`amount-and-employee-code-form_ ${clientName}`}>
                                    <div className={`pay-now-input-group_ ${clientName}`}>
                                        <div className={`pay-now-label_ ${clientName}`}>Employee Code</div>
                                        <input
                                            value={employeeId}
                                            type="text"
                                            onChange={(e) => setEmployeeCode(e.target.value)}
                                            className={`pay-now-deposit-input_ ${clientName}`}
                                        />
                                    </div>
                                    <div className={`pay-now-input-group_ ${clientName}`}>
                                        <div className={`pay-now-label_ ${clientName}`}>Amount*</div>
                                        <input
                                            type="number"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                            className={`pay-now-deposit-input_ ${clientName}`}
                                        />
                                    </div>
                                    <div className="deposit-form-actions_">
                                        <button onClick={handlePayNow} className='pay-now-button_'>
                                            {formLoader ? <CircularProgress className='form-loader_' size={18} /> : "Pay Now"}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Payment Methods */}
                        </div>
                }
            </section>

        </>
    );
};

export default React.memo(OSDepositIntentPage);
