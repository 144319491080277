import React, { useState } from 'react'
import "./KycOtpForm.css"
import OtpInput from "otp-input-react";
import toast from 'react-hot-toast';
import { decryptData } from '../../../util/DecryptData';
import { encryptData } from '../../../util/EncryptData';
import axios from 'axios';
import { CircularProgress } from '@mui/material';

const KycOtpForm = ({ setShowOtpForm, kycDetails, userId, token, clientName, API_ENDPOINT, resetForm }) => {
    const [kycOtp, setKycOTP] = useState('');
    const [otpFormLoader, setOtpFormLoader] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);


    const cancelKyc = () => {
        resetForm();
        setShowOtpForm(false);
    }

    // handle otp submit
    const handleOtpSubmit = async () => {
        if (kycOtp.length >= 5) {
            let payload = {
                accountNumber: kycDetails.accountNumber,
                otp: kycOtp,
                aadhaarNumber: kycDetails.aadharNumber,
                _id: userId,
                upiId: kycDetails.upiId
            };
            setOtpFormLoader(true);
            await axios.post(
                `${API_ENDPOINT}/kyc/kycOtp`,
                { token: encryptData(payload) },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'client-name': clientName?.toUpperCase(),
                        'Authorization': `Bearer ${token}`,
                    },
                }
            )
                .then((response) => {
                    if (response && response.data && response.data.success) {
                        let API_RESPONSE = decryptData(response.data.data);
                        console.log(API_RESPONSE);
                        setShowSuccessPopup(true);
                        setOtpFormLoader(false);
                        resetForm();
                        setKycOTP("");
                    }

                })
                .catch((error) => {
                    setOtpFormLoader(false);
                    toast.error(error.response.data.message);
                    if (error.response.status === 404) {
                        resetForm();
                        setKycOTP("");
                        setOtpFormLoader(false);
                    }
                })
                
        } else {
            toast.error("Invalid OTP");
        }
    }



    // send message to app for next step
    const handleSendMessageToNativeApp = () => {
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ success: true }));
            setShowOtpForm(false);
            setShowSuccessPopup(false);
        } else {
            toast.error("Unable to Proceed Next Step !");
            setShowOtpForm(false);
            setShowSuccessPopup(false);

        }
    }




    return <>
        <div hidden={showSuccessPopup} className="kyc-otp-page-section_">
            <div className="kyc-otp-form_">
                <div className="kyc-otp-form-group_">
                    <div className="kyc-otp-form-input-label_"></div>
                    <OtpInput
                        onChange={setKycOTP}
                        value={kycOtp}
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                        autoFocus
                        className="otp-input-box_"
                        inputStyle={{
                            backgroundColor: "red",
                        }}
                    ></OtpInput>
                </div>
                <div className="kyc-otp-form-actions_">
                    {/* <button disabled={otpFormLoader} onClick={cancelKyc} className={`kcy-otp-form-action-button_ ${otpFormLoader ? 'disabled' : ''}`}>
                        Cancel
                    </button> */}
                    <button disabled={otpFormLoader} onClick={handleOtpSubmit} className="kcy-otp-form-action-button_">
                        {otpFormLoader ? <CircularProgress size={18} className='form-loader_' /> : "Submit"}
                    </button>
                </div>
            </div>
        </div>

        {
            showSuccessPopup &&
            <div className="after-kyc-popup-box_">
                <div className="after-kyc-popup-box-container_">
                    <div className="after-kyc-success-message_">KYC Successfully 😊</div>
                    <button onClick={handleSendMessageToNativeApp} className="after-kyc-popup-box-action-btn_">Ok</button>
                </div>
            </div>
        }

    </>
}

export default KycOtpForm