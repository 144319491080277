import React from 'react'
import "./NotFoundPage.css"
const NotFoundPage = () => {
  return <>
  <section className="not-found-page_">
    <div className="not-found-content_">
        <h1 className='not-found-message_'>404 Page Not Found</h1>
    </div>
  </section>
  
  </>
}

export default NotFoundPage