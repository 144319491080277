import React, { useState } from 'react'
import "./OSDepositIntentPage.css"

import { encryptData } from '../../util/EncryptData';
import { decryptData } from '../../util/DecryptData';
import toast from 'react-hot-toast';
import axios from 'axios';
const PayingAccountsPage = (
    { payingAccounts,
        clientName,
        empCode,
        userCity,
        userEmail,
        userId,
        userMobile,
        employeeId,
        amount,
        lat,
        lng,
        postCode,
        platform,
        userState,
        setLoader,
        userName,
        token,
        API_ENDPOINT

    }) => {


    // handle deposit submit
    const handleDepositSubmit = async (account) => {
        setLoader(true);
        const payload = {
            empCode: employeeId,
            mode: 'phonPay',
            userId,
            amount,
            paymentGatewayName: account.name ?? '',
            userName,
            userEmail,
            userMobile,
            userCity,
            paymentType: 'deposit',
            userState,
            lat: lat?.toString() ?? '',
            lng: lng?.toString() ?? '',
            platform,
            postcode: postCode,
        };

        try {
            const response = await axios.post(
                `${API_ENDPOINT}/transaction/${account?.name}`,
                { token: encryptData(payload) },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'client-name': clientName?.toUpperCase(),
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            const data = decryptData(response.data.data);
            setLoader(false);

            if (account?.gatewayType === "webLink") {
                window.location.href = data.payload.url;
            } else if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ paymentType: account.gatewayType, response: data.payload }));
            }
        } catch (error) {
            setLoader(false);
            console.error("Deposit error", error);
            toast.error(error.response?.data?.message || "Something went wrong");
        }
    }



    return <>
        <div className="payment-accounts-page_">
            <div className={`payment-accounts-container_ ${clientName}`}>
                {payingAccounts.length === 0 ? (
                    <p className={`no-payment-accounts-message_ ${clientName}`}>
                        Please select proper minimum and maximum amount limit
                    </p>
                ) : (
                    payingAccounts.map((account) => (
                        <div
                            key={account.name}
                            className={`payment-accounts-box_ ${clientName}`}
                            onClick={() => handleDepositSubmit(account)}
                        >
                            <div className={`payment-accounts-category_ ${clientName}`}>{account.name}</div>
                            <div className={`payment-accounts-list_ ${clientName}`}>
                                <div className={`payment-accounts-banner_ ${clientName}`}>
                                    <img src={account.gatewayImage} className={`getway-banner_ ${clientName}`} alt="" />
                                </div>
                            </div>
                        </div>
                    ))
                )}
            </div>

        </div>
    </>
}

export default PayingAccountsPage