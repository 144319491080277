import React, { useCallback, useEffect, useState } from 'react';
import "./KycPage.css";
import KycOtpForm from './KycOtpForm';
import { validateAadharNumber, validateBankAccount, validateIfscCode, validateUpiId } from '../../../util/inputValidators';
import axios from 'axios';
import { encryptData } from '../../../util/EncryptData';
import toast from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
import { decryptData } from '../../../util/DecryptData';
import { useParams } from 'react-router-dom';
import { FadeLoader } from "react-spinners"

var API_ENDPOINT = "https://laxminarayan.live/api";

const defaultFormData = {
    accountNumber: "",
    ifscCode: "",
    aadharNumber: "",
    upiId: ""
};

// Initial error state for each field
// Error structure
const createErrorStructure = () => [
    { field: 'bankAccountNumber', message: '', isValid: true },
    { field: 'ifscCode', message: '', isValid: true },
    { field: 'aadharNumber', message: '', isValid: true },
    { field: 'upiId', message: '', isValid: true }
];


const KycPage = () => {
    const { token, userId, clientName } = useParams();
    const [formData, setFormData] = useState(defaultFormData);
    const [errors, setErrors] = useState(createErrorStructure());
    const [showOtpForm, setShowOtpForm] = useState(false);
    const [kycFormLoader, setkycFormLoader] = useState(false);

    const [paramError, setParamError] = useState('');

    // Validate parameters on component mount
    useEffect(() => {
        const missingParams = [];
        if (!token) missingParams.push('token');
        if (!userId) missingParams.push('userId');
        if (!clientName) missingParams.push('clientName');

        if (missingParams.length > 0) {
            const errorMsg = `Missing required parameters: ${missingParams.join(', ')}`;
            setParamError(errorMsg);
            toast.error(errorMsg);
        }
    }, [token, userId, clientName]);

    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));

        // Clear error when user starts typing
        if (errors.some(err => err.field === name && !err.isValid)) {
            setErrors(errors.map(err =>
                err.field === name ? { ...err, message: '', isValid: true } : err
            ));
        }
    };

    //reset form data 
    const resetForm =() => {
        setFormData(defaultFormData);
        setkycFormLoader(false);
    }

    const validateForm = () => {
        const newErrors = createErrorStructure();
        let isValid = true;

        // Validate each field
        newErrors[0].message = validateBankAccount(formData.accountNumber);
        newErrors[1].message = validateIfscCode(formData.ifscCode);
        newErrors[2].message = validateAadharNumber(formData.aadharNumber);
        newErrors[3].message = validateUpiId(formData.upiId);

        // Check validation results
        newErrors.forEach((err, index) => {
            if (err.message) {
                newErrors[index].isValid = false;
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };


    // handle kyc details submit
    const handleSubmitKycDetails = async (e) => {
        e.preventDefault();

        // Check for parameter errors before submission
        if (paramError) {
            toast.error('Cannot proceed due to missing parameters');
            return;
        }

        if (!validateForm()) return;

        if (validateForm()) {
            let payload = {
                accountNumber: formData.accountNumber,
                ifsc: formData.ifscCode,
                aadhaarNumber: formData.aadharNumber,
            };

            console.log(payload)
            setkycFormLoader(true);
            await axios.post(
                `${API_ENDPOINT}/kyc/kyc`,
                { token: encryptData(payload) },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'client-name': clientName?.toUpperCase(),
                        'Authorization': `Bearer ${token}`,
                    },
                }
            )
                .then((response) => {
                    if (response && response.data && response.data.success) {
                        let API_RESPONSE = decryptData(response.data.data);
                        console.log(API_RESPONSE);
                        toast.success("OTP Sent Successfully");
                        setkycFormLoader(false);
                        setShowOtpForm(true);
                    }

                })
                .catch((error) => {
                    setkycFormLoader(false);
                    toast.error(error.response.data.message)
                })


        }
    };


    if (paramError) {
        return (
            <div className="kyc-page-section_">
                <div className="kyc-page-section-container_">
                    <div className="error-message_">
                        {paramError}. Please contact support.
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="kyc-page-section_">
            <div className="kyc-page-section-container_">
                <div className="kyc-page-section-header_">
                    <div className="kcy-details-heading_">{showOtpForm ? "OTP" : "KYC"} Details</div>
                </div>

                {!showOtpForm ? (
                    <div className="kyc-details-form_" onSubmit={handleSubmitKycDetails}>
                        {kycFormLoader &&
                            <div className="kyc-loading-screen_">
                                <FadeLoader color='#fff' className='form-loader_' />
                            </div>}
                        <div className="kyc-details-form-group_">
                            <div className="kyc-details-form-group-label_">Enter Bank Account No</div>
                            <input
                                value={formData.accountNumber}
                                onChange={handleInputChange}
                                name='accountNumber'
                                type="number"
                                placeholder='Bank Account Number'
                                className={`kyc-details-form-group-input_ ${!errors[0].isValid ? 'error' : ''}`}
                            />
                            {!errors[0].isValid && (
                                <div className="form-error_">{errors[0].message}</div>
                            )}
                        </div>

                        <div className="kyc-details-form-group_">
                            <div className="kyc-details-form-group-label_">Enter Bank IFSC Code</div>
                            <input
                                value={formData.ifscCode}
                                onChange={handleInputChange}
                                name='ifscCode'
                                type="text"
                                placeholder='IFSC'
                                className={`kyc-details-form-group-input_ ${!errors[1].isValid ? 'error' : ''}`}
                            />
                            {!errors[1].isValid && (
                                <div className="form-error_">{errors[1].message}</div>
                            )}
                        </div>

                        <div className="kyc-details-form-group_">
                            <div className="kyc-details-form-group-label_">Enter Aadhar No </div>
                            <input
                                value={formData.aadharNumber}
                                onChange={handleInputChange}
                                name='aadharNumber'
                                type="number"
                                placeholder='Aadhar No '
                                className={`kyc-details-form-group-input_ ${!errors[2].isValid ? 'error' : ''}`}
                            />
                            {!errors[2].isValid && (
                                <div className="form-error_">{errors[2].message}</div>
                            )}
                        </div>

                        <div className="kyc-details-form-group_">
                            <div className="kyc-details-form-group-label_">Enter UPI Id (For Withdraw)</div>
                            <input
                                value={formData.upiId}
                                onChange={handleInputChange}
                                name='upiId'
                                type="text"
                                placeholder='UPI Id '
                                className={`kyc-details-form-group-input_ ${!errors[3].isValid ? 'error' : ''}`}
                            />
                            {!errors[3].isValid && (
                                <div className="form-error_">{errors[3].message}</div>
                            )}
                        </div>

                        <div className="kyc-details-form-actions_">
                            <button disabled={kycFormLoader} onClick={handleSubmitKycDetails} type="submit" className="kyc-details-form-action-btn_">
                                {"Submit"}
                            </button>
                        </div>
                    </div>
                ) : (
                    <KycOtpForm
                        setShowOtpForm={setShowOtpForm}
                        kycDetails={formData}
                        userId={userId}
                        token={token}
                        clientName={clientName}
                        API_ENDPOINT={API_ENDPOINT}
                        resetForm={resetForm}
                    />
                )}
            </div>
        </div>
    );
};

export default KycPage;