import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import OSDepositIntentPage from './pages/OSDepositIntentPage/OSDepositIntentPage'
import NotFoundPage from './pages/NotFound/NotFoundPage'
import KycPage from "./pages/OSPages/KycPage/KycPage"
import "./App.css"
import WithdrawalPage from './pages/OSPages/WithdrawlPage/WithdrawalPage'
import { Toaster } from 'react-hot-toast'
const App = () => {
  return <>
    <BrowserRouter>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: 'radial-gradient(#00AE0C, #007025)',
              color: "#fff"
            },
          },
          error: {
            style: {
              background: 'radial-gradient(#90101A, #4E070C)',
              color: "#fff"
            },
          },

        }}
      />
      <Routes>

        <Route
          path={`/utr-deposit/:token/:empId/:lat/:lng/:postCode/:userCity/:userEmail/:userId/:userMobile/:userName/:userState/:deviceId/:clientName/:appVersion/:platform`}
          element={<OSDepositIntentPage />} />
        <Route path='/w-kyc/:token/:userId/:clientName' element={<KycPage />} />
        <Route path='/w-withdrawal/:token/:userId/:clientName/:bankName/:accountHolderName/:bankAccountNumber/:ifsc' element={<WithdrawalPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  </>
}

export default App